/** @jsx jsx */

import { css, jsx } from '@emotion/react'
import { graphql, useStaticQuery } from 'gatsby'

import { Container } from 'src/components/common/Container'
import { Squidex_Landing } from 'src/types/gatsby-types'
import { TestimonialsPageItem } from 'src/components/TestimonialsPage/TestimonialsPageItem'
import { headingSecondary } from 'src/components/common/Typography'
import { mq } from 'src/utils/mq'

type Data = {
  squidex: {
    queryLandingContents: Squidex_Landing[]
  }
}

const wrapperStyles = css`
  margin-top: 45px;
  margin-bottom: 45px;

  ${mq.md} {
    margin-top: 115px;
    margin-top: 70px;
    padding: 0 16px;
  }
`

export const TestimonialsPage: React.FC = () => {
  const response = useStaticQuery<Data>(graphql`
    {
      squidex {
        queryLandingContents {
          flatData {
            testimonials {
              id
              flatData {
                author
                position
                content
                caseLink
                picture {
                  ...AuthorImage
                }
              }
            }
          }
        }
      }
    }
  `)

  const testimonials =
    response?.squidex?.queryLandingContents?.[0]?.flatData?.testimonials

  if (!testimonials?.length) {
    throw new Error('No data for testimonials page found')
  }

  return (
    <Container css={wrapperStyles}>
      <h2 css={[headingSecondary, { textAlign: 'center' }]}>Testimonials</h2>

      {testimonials.map((testimonial) => (
        <TestimonialsPageItem testimonial={testimonial} key={testimonial.id} />
      ))}
    </Container>
  )
}
