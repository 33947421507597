import { InterestedInServicesForm } from 'src/components/InterestedInServicesForm/InterestedInServicesForm'
import { Layout } from 'src/components/Layout/Layout'
import { PageProps } from 'gatsby'
import React from 'react'
import { TestimonialsPage } from 'src/components/TestimonialsPage/TestimonialsPage'

const Testimonials: React.FC<PageProps<{}>> = () => {
  return (
    <Layout title="Testimonials | RabbitPeepers">
      <TestimonialsPage />
      <InterestedInServicesForm />
    </Layout>
  )
}

export default Testimonials
